/**
 * Home
 *
 * 01. Anchor Link
 *
 * @global jQuery
 * @author Matteo Casonato
 */

(function ($) {
    'use strict';

    /**
     * Anchor Link
     *
     * @summary Scroll anchor
     */
    $(".js-scroll").click(function() {
        $('html, body').animate({
            scrollTop: $($(this).attr("href")).offset().top - 100
        }, {
            duration: 1000,
            easing: "swing"
        });
        return false;
    });

}(jQuery));