/**
 * Global
 *
 * 01. Pongho Ajax Form
 * 02. SVG
 * 03. Social Share
 * 04. Google Maps
 * 04. Cookie iFrame
 * 05. Animation
 * 06. Hero Video
 *
 * @global jQuery
 * @author Matteo Casonato
 * @author Daniele De Nobili
 */


/**
 * Pongho Ajax Form
 *
 * @requires plugins/pongho-form.js
 */
(function ($) {
	'use strict';

	$('.js-ajaxform').ponghoForm({
		wrapperClass: 'ajax-form-wrapper',
		onSubmit: function (data, form) {
			if (window.ga) {
				// https://developers.google.com/analytics/devguides/collection/analyticsjs/events
				window.ga(
					'send',
					{
						'hitType': 'event', // Required.
						'eventCategory': 'form', // Required.
						'eventAction': 'submit', // Required.
						'eventLabel': form.data('ga-label') || form.prop('name'),
						'page': window.location.pathname
					}
				);
			}
		}
	});

}(jQuery));


/**
 * SVG
 *
 * @requires libraries/svg4everybody.js
 * @requires ie/svg4everybody.legacy.js
 */
(function ($) {
	'use strict';

	svg4everybody();

}(jQuery));


/**
 * Social Share
 *
 * @global jQuery
 * @requires plugins/jquery.social.js
 */
(function ($) {
	'use strict';

	if (typeof fb_appId !== 'undefined') {
		$('.social').social({
			template: {
				'facebook-share': '<a href="#" class="social__link"><span class="social__icon"><svg><use xlink:href="' + templateUrl + '/images/svg/sprite.svg#icons--facebook"></use></svg></span><span class="social__count">{total}</span></a>',
				'googleplus': '<a href="#" class="social__link"><span class="social__icon"><svg><use xlink:href="' + templateUrl + '/images/svg/sprite.svg#icons--google-plus"></use></svg></span><span class="social__count">{total}</span></a>',
				'twitter': '<a href="#" class="social__link"><span class="social__icon"><svg><use xlink:href="' + templateUrl + '/images/svg/sprite.svg#icons--twitter"></use></svg></span><span class="social__count">Tweet</span></a>',
				'pinterest': '<a href="#" class="social__link"><span class="social__icon"><svg><use xlink:href="' + templateUrl + '/images/svg/sprite.svg#icons--pinterest"></svg></use></span><span class="social__count">Pin</span></a>'
			},
			totalShareSelector: '#social-count',
			lang: fb_lang || 'en_US',
			facebookAppId: fb_appId || '',
			countStatsUrl: null,
			enableTracking: {
				'facebook-share': 10,
				'googleplus': 10,
				'twitter': 10,
				'pinterest': 10
			}
		});
	}

}(jQuery));


/**
 * Google Maps
 *
 * @summary Mappa personalizzata con https://snazzymaps.com/
 * @requires plugins/jquery.googlemaps.js
 */
(function ($) {
	'use strict';

	var maps = $('.js-gmap');

	if (!maps.length) {
		return;
	}

	window.onFirstUserInteraction(function(){
		var d = document,
			t = 'script';

		function l(u) {
			var j = d.createElement(t),
				s = d.getElementsByTagName(t)[0];

			j.src = u;
			j.async = 1;
			s.parentNode.insertBefore(j, s);
		}

		l('//maps.googleapis.com/maps/api/js?key=AIzaSyAO4ycwmVie3JhnpJx7-_b_kg629w_g7ZU&v=3.exp&callback=initialize');
	});

	window.initialize = function () {
		maps.googlemaps({
			style: [{"featureType":"landscape","stylers":[{"hue":"#FFBB00"},{"saturation":43.400000000000006},{"lightness":37.599999999999994},{"gamma":1}]},{"featureType":"road.highway","stylers":[{"hue":"#FFC200"},{"saturation":-61.8},{"lightness":45.599999999999994},{"gamma":1}]},{"featureType":"road.arterial","stylers":[{"hue":"#FF0300"},{"saturation":-100},{"lightness":51.19999999999999},{"gamma":1}]},{"featureType":"road.local","stylers":[{"hue":"#FF0300"},{"saturation":-100},{"lightness":52},{"gamma":1}]},{"featureType":"water","stylers":[{"hue":"#0078FF"},{"saturation":-13.200000000000003},{"lightness":2.4000000000000057},{"gamma":1}]},{"featureType":"poi","stylers":[{"hue":"#00FF6A"},{"saturation":-1.0989010989011234},{"lightness":11.200000000000017},{"gamma":1}]}]
		});
	};

}(jQuery));


/**
 * Cookie iFrame
 */
(function ($) {
	'use strict';

	window.onFirstUserInteraction(function() {
		$('iframe').attr('src', function () {
			return $(this).data('src');
		});
	});

}(jQuery));


/**
 * Animation
 *
 * @summary Scroll animations
 * @requires plugins/wow.js
 */
var wow = new WOW({
	boxClass: 'js-wow',
	offset: 0,
	animateClass: 'is-animated'
});

wow.init();


/**
 * Hero Video
 *
 * @summary fullscreen background videos
 * @requires modernizr.min.js
 */
$(function () {
	'use strict';

	var videoContainer = $('.js-hero');

	if (!videoContainer.length) {
		return;
	}

	if (window.matchMedia('(min-width: 768px)').matches) {

		var video = document.createElement('video');
		video.autoplay = true;
		video.muted = true;
		video.loop = true;
		video.className = 'hero__media';

		var canPlayMp4 = typeof video.canPlayType === 'function' && video.canPlayType('video/mp4');
		canPlayMp4 = canPlayMp4 === 'maybe' || canPlayMp4 === 'probably';

		var videoSrc = canPlayMp4 ? videoContainer.data('mp4') : videoContainer.data('webm');
		var videoType = canPlayMp4 ? 'video/mp4' : 'video/webm';

		var oReq = new XMLHttpRequest();

		oReq.open('POST', videoSrc, true);
		oReq.responseType = 'arraybuffer';

		oReq.onload = function () {
			var blob = new Blob([oReq.response], {
				type: videoType
			});
			var reader = new FileReader();

			reader.readAsDataURL(blob);

			reader.onload = function () {
				var dataURL = reader.result;

				$('.hero__cover').hide();

				var source = document.createElement('source');
				source.type = videoType;
				source.src = dataURL;

				video.appendChild(source);

				videoContainer.html(video);
			};
		};

		oReq.send();
	}
}());


/**
 * Modulo di contatto
 */
(function ($) {
	'use strict';

	var master = $('.js-type'),
		slave = $('.js-vatnumber'),
		slaveContainer = slave.closest('.form__field');

	slaveContainer.addClass('form__field--required')
		.find('label').append('<span class="form__required" title="Campo obbligatorio">Campo obbligatorio</span>');

	function handle() {
		if (master.val() === 'Professionista' || master.val() === 'Professional') {
			slave.attr('required', true);
			slaveContainer.show();
		} else {
			slave.attr('required', false);
			slaveContainer.hide();
		}
	}

	master.on('change', handle);
	handle();

}(jQuery));
