/**
 * Product
 *
 * 01. Related
 * 02. Image Zoom
 *
 * @global jQuery
 * @author Matteo Casonato
 */

(function ($) {
	'use strict';
	
	/**
	 * Related
	 *
	 * @requires plugins/jquery.slick.js
	 */
	var related = $('.js-related');

	related.on('init', function() {
		related.removeClass('related__content--load')
	});

	related.slick({
		appendDots: $('.related__headline'),
		dotsClass: 'related__dots slick-dots',
		arrows: false,
		dots: true,
		infinite: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		mobileFirst: true,
		responsive: [
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4
				}
			}
		]
	});


	/**
	 * Image Zoom
	 *
	 * @link http://codepen.io/o-l-e/pen/PwrRWp
	 *
	 * @requires plugins/photoswipe.js
	 * @requires plugins/photoswipe_ui.js
	 */
	$.fn.photoswipe = function(options) {
		var galleries = [],
			_options = options;
		var parseImage = function($link, size) {
			var sizeItem;
			if ($link.attr('data-' + size) != 'undefined' && $link.attr('data-' + size + '-size') != 'undefined') {
				var imageSize = $link.data(size + '-size').split('x');
				if (imageSize.length != 2) {
					throw SyntaxError("Missing data-size attribute.");
				}
				sizeItem = {
					src: $link.data(size),
					w: parseInt(imageSize[0], 10),
					h: parseInt(imageSize[1], 10)
				}
			} else {
				throw SyntaxError("Missing data-* attributes for " + size);
			}
			return sizeItem;
		}
		var init = function($this) {
			galleries = [];
			$this.each(function(i, gallery) {
				galleries.push({
					id: i,
					items: []
				});
				$(gallery).find('a').each(function(k, link) {
					var $link = $(link);
					$link.data('gallery-id', i + 1);
					$link.data('photo-id', k);
					var item = {
						src: link.href,
						msrc: link.children[0].getAttribute('alt-src'),
						title: $link.data('title'), // for captions using data-title attribute, add data-title="The caption", uncomment this line and comment line below
						// title: $link.children('figcaption').text(),
						el: link,
						smallImage: parseImage($link, 'small'),
						mediumImage: parseImage($link, 'medium'),
						largeImage: parseImage($link, 'large')
					};
					galleries[i].items.push(item);
				});
				$(gallery).on('click', 'a', function(e) {
					e.preventDefault();
					var gid = $(this).data('gallery-id'),
						pid = $(this).data('photo-id');
					openGallery(gid, pid);
				});
			});
		}
		var parseHash = function() {
			var hash = window.location.hash.substring(1),
				params = {};
			if (hash.length < 5) {
				return params;
			}
			var vars = hash.split('&');
			for (var i = 0; i < vars.length; i++) {
				if (!vars[i]) {
					continue;
				}
				var pair = vars[i].split('=');
				if (pair.length < 2) {
					continue;
				}
				params[pair[0]] = pair[1];
			}
			if (params.gid) {
				params.gid = parseInt(params.gid, 10);
			}
			if (!params.hasOwnProperty('pid')) {
				return params;
			}
			params.pid = parseInt(params.pid, 10);
			return params;
		};
		var openGallery = function(gid, pid) {
			var pswpElement = document.querySelectorAll('.pswp')[0],
				items = galleries[gid - 1].items,
				options = {
					index: pid,
					galleryUID: gid,
					getThumbBoundsFn: function(index) {
						var thumbnail = items[index].el.children[0],
							pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
							rect = thumbnail.getBoundingClientRect();
						return {
							x: rect.left,
							y: rect.top + pageYScroll,
							w: rect.width
						};
					}
				};
			$.extend(options, _options);
			var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
			// create variable that will store real size of viewport
			var realViewportWidth,
				useImageSize = 'large',
				firstResize = true,
				imageSrcWillChange;
			// beforeResize event fires each time size of gallery viewport updates
			gallery.listen('beforeResize', function() {
				// calculate real pixels when size changes
				// realViewportWidth = gallery.viewportSize.x * window.devicePixelRatio;
				realViewportWidth = gallery.viewportSize.x;
				// Code below is needed if you want image to switch dynamically on window.resize
				// Find out if current images need to be changed
				if (useImageSize != 'small' && realViewportWidth < gallery.options.breakpoints.medium) {
					useImageSize = 'small';
					imageSrcWillChange = true;
				} else if (useImageSize != 'medium' && (realViewportWidth >= gallery.options.breakpoints.medium && realViewportWidth < gallery.options.breakpoints.large)) {
					useImageSize = 'medium';
					imageSrcWillChange = true;
				} else if (useImageSize != 'large' && realViewportWidth >= gallery.options.breakpoints.large) {
					useImageSize = 'large';
					imageSrcWillChange = true;
				}
				// Invalidate items only when source is changed and when it's not the first update
				if (imageSrcWillChange && !firstResize) {
					// invalidateCurrItems sets a flag on slides that are in DOM, which will force update of content (image) on window.resize.
					gallery.invalidateCurrItems();
				}
				if (firstResize) {
					firstResize = false;
				}
				imageSrcWillChange = false;
			});
			// gettingData event fires each time PhotoSwipe retrieves image source & size
			gallery.listen('gettingData', function(index, item) {
				// Set image source & size based on real viewport width
				if (useImageSize == 'large') {
					item.src = item.largeImage.src;
					item.w = item.largeImage.w;
					item.h = item.largeImage.h;
				} else if (useImageSize == 'medium') {
					item.src = item.mediumImage.src;
					item.w = item.mediumImage.w;
					item.h = item.mediumImage.h;
				} else if (useImageSize == 'small') {
					item.src = item.smallImage.src;
					item.w = item.smallImage.w;
					item.h = item.smallImage.h;
				}
				// It doesn't really matter what will you do here, as long as item.src, item.w and item.h have valid values. Just avoid http requests in this listener, as it fires quite often
			});
			gallery.init();
		};
		// initialize
		init(this);
		// Parse URL and open gallery if it contains #&pid=3&gid=1
		var hashData = parseHash();
		if (hashData.pid > 0 && hashData.gid > 0) {
			openGallery(hashData.gid, hashData.pid);
		}
		return this;
	};

	// init
	$('.js-photoswipe').photoswipe({
		// image breakpoint options
		breakpoints: { // small images are below the medium breakpoint
			medium: 479, // medium sizes above this
			large: 767 // large sizes above this
		},
		// options
		bgOpacity: 0.9,
		loop: true,
		history: false,
		captionEl: true,
		fullscreenEl: false,
		shareEl: false,
		tapToClose: true,
		tapToToggleControls: false,
		closeOnScroll: false,
		closeOnVerticalDrag: false,
		zoomEl: false,
		counterEl: false,
		arrowEl: true
	});

}(jQuery));
