/**
 * network.js
 *
 * @author Daniele De Nobili
 */

/*jslint nomen: true, plusplus: true, passfail: true, browser: true, devel: true */
/*global $ */

(function ($) {
    "use strict";

    $('.js-network-select').on('change', function () {
        var url = $(this).find(':selected').val();

        if (url) {
            window.location = url;
        }
    });
}(jQuery));
