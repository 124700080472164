/**
 * pongho-form.js
 *
 * @author Daniele De Nobili
 */

/*jslint nomen: true, plusplus: true, passfail: true, browser: true, devel: true */
/*global jQuery */

(function ($) {
    "use strict";

    var defaults = {
        wrapperClass: 'pongho-form-wrapper',
        onSubmit: $.noop,
        onFieldError: function (field) {
            field.closest('.form__field').addClass('form__field--error');
        },
        messageTpl: '<div class="alert alert--success form__alert"><p>{MESSAGE}</p></div>',
        errorTpl: '<div class="alert alert--error form__alert">{ERRORS}</div>'
    };

    $.fn.ponghoForm = function (opts) {
        opts = $.extend({}, defaults, opts);

        return $(this).each(function () {
            var wrapper = $(this).wrap('<div />').parent().addClass(opts.wrapperClass);

            wrapper.on('submit', function (event) {
                event.preventDefault();

                var form = $(event.target),
                    method = form.prop('method').toUpperCase();

                wrapper
                    .addClass('loading')
                    .find('.alert').remove();

                form.find('.alert--error').removeClass('alert--error');

                $.ajax({
                    url: form.prop('action'),
                    data: form.serialize(),
                    dataType: 'json',
                    method: method, // jQuery 1.9+
                    type: method, // jQuery <1.9
                    success: function (data) {
                        var errorList = '',
                            redirect,
                            currentUrl;

                        wrapper.removeClass('loading');

                        /**
                         * Vengono gestite queste risposte:
                         *
                         * { error: true, message: "Error message" }
                         * { error: true, errors: { name: "The field name is required", field: "Error field" } }
                         * { error: false, message: "OK" }
                         * { redirect: "http://example.com/redirect" }
                         * { html: "<form></form>" }
                         * { error: true, html: "<form></form>" }
                         */

                        if (data.html) {
                            wrapper.html(data.html);
                        } else if (data.error) {
                            if (typeof data.errors === 'object') {

                                $.each(data.errors, function (fieldName, errorMessage) {
                                    errorList += '<li>' + errorMessage + '</li>';

                                    if ($.isFunction(opts.onFieldError)) {
                                        opts.onFieldError($('input[name="' + fieldName + '"]'));
                                    }
                                });

                                wrapper.prepend(
                                    opts.errorTpl.replace('{ERRORS}', '<ul>' + errorList + '</ul>')
                                );

                                return;
                            }

                            // errore di sistema
                            throw data.message;
                        } else if (data.message) {
                            wrapper.html(
                                opts.messageTpl.replace('{MESSAGE}', data.message)
                            );
                        }

                        if (!data.error) {
                            opts.onSubmit(data, form);
                        }

                        if (data.redirect) {
                            redirect = data.redirect.split('#');
                            currentUrl = window.location.href.split('#');

                            if (redirect[1] && redirect[0] === currentUrl[0]) {
                                window.location.replace(data.redirect);
                                window.location.reload();
                            } else {
                                window.location.replace(data.redirect);
                            }
                        }
                    }
                });
            });
        });
    };
}(jQuery));
