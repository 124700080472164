/**
 * Download
 *
 * 01. Attachment Filter
 *
 * @author Matteo Casonato
 * @link https://haroen.me/holmes/
 * @requires plugins/holmes.js
 */

(function ($) {
	'use strict';

	var container = $('.js-attachment');

	if (!container.length) {
		return;
	}

	holmes({
		input: '.attachment__input',
		find: '.attachment__item',
		placeholder: container.data('noresults'),
		class: {
			visible: 'attachment__item--visible',
			hidden: 'attachment__item--hidden'
		},
		dynamic: false,
		instant: true,
		minCharacters: 2
	});

}(jQuery));