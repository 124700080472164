/**
 * jQuery GoogleMaps
 *
 * @author Daniele De Nobili
 */

/*jslint nomen: true, plusplus: true, passfail: true, browser: true, devel: true */
/*global jQuery, google */

(function ($) {
    "use strict";

    var defaults = {
        zoom: 7,
        zoomstyle: 'DEFAULT',
        menustyle: 'DEFAULT',
        type: 'ROADMAP',
        icon: null,
        bounds: false, // solo per più località
        alwayscenter: 1,  // torna al centro dopo 1 secondo, impostare a 0 per disattivare
        centerprecision: {lat: 0, lng: 0}
    };

    $.fn.googlemaps = function (opts) {
        var _opts = $.extend({}, defaults, opts);

        if (!google) {
            throw "Google Maps is not loaded!";
        }

        return this.each(function (index, element) {
            var
                $element = $(element),
                addresses = $element.children(),
                opts = $.extend({}, _opts, $element.data()),
                mapOptions,
                map,
                infoWindow;

            // centro della mappa
            if (opts.centerlat === undefined) {
                opts.centerlat = 0;

                addresses.each(function () {
                    opts.centerlat += $(this).data('lat') || 0;
                });

                opts.centerlat /= addresses.length;
            }

            if (opts.centerlng === undefined) {
                opts.centerlng = 0;

                addresses.each(function () {
                    opts.centerlng += $(this).data('lng') || 0;
                });

                opts.centerlng /= addresses.length;
            }

            mapOptions = {
				draggable: true,
                zoom: opts.zoom,
                center: new google.maps.LatLng(opts.centerlat + opts.centerprecision.lat, opts.centerlng + opts.centerprecision.lng),
                scrollwheel: false,

                // Stile del Menu
                mapTypeControl: false,
                mapTypeControlOptions: {
                    style: google.maps.MapTypeControlStyle[opts.menustyle],
                    position: google.maps.ControlPosition.TOP_LEFT
                },

                // Stile del Navigatore
                panControl: true,
                panControlOptions: {
                    position: google.maps.ControlPosition.LEFT_CENTER
                },

                // Stile di Street View
                streetViewControl: false,
                streetViewControlOptions: {
                    position: google.maps.ControlPosition.LEFT_CENTER
                },

                // Stile dello Zoom
                zoomControl: true,
                zoomControlOptions: {
                    style: google.maps.ZoomControlStyle[opts.zoomstyle],
                    position: google.maps.ControlPosition.LEFT_CENTER
                },

                // Stile della Mappa
                mapTypeId: google.maps.MapTypeId[opts.type]
            };

            // Stile della mappa (http://snazzymaps.com/)
            if (opts.style) {
                //console.log(typeof opts.style);

                switch (typeof opts.style) {
                    case 'string':
                        if (window[opts.style]) {
                            mapOptions.styles = window[opts.style];
                        }
                        break;

                    case 'object':
                        mapOptions.styles = opts.style;
                        break;
                }
            }

            // Genero la mappa
            map = new google.maps.Map(element, mapOptions);

            google.maps.event.addListenerOnce(map, 'idle', function () {
                // Popup
                infoWindow = new google.maps.InfoWindow({
                    content: 'loading…',
                    maxWidth: 310
                });
    
                // Aggiungo i marcatori
                addresses.each(function () {
                    var $address = $(this),
                        marker = new google.maps.Marker({
                            position: new google.maps.LatLng($address.data('lat'), $address.data('lng')),
                            map: map,
                            //animation: google.maps.Animation.DROP,
                            icon: $address.data('icon') || _opts.icon,
                            html: '<div class="infowindow">' + $address.html() + '</div>'
                        });
    
                    function openInfoWindow() {
                        infoWindow.setContent(marker.html);
                        infoWindow.open(map, marker);
                    }
    
                    // Gestisce l'evento dei Baloon
                    google.maps.event.addListener(marker, 'click', openInfoWindow);
    
                    if ($element.data("openwindow") || $address.data("openwindow")) {
                        openInfoWindow();
                    }
                });
            });

        });
    };

})(jQuery);
