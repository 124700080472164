/**
 * Access Private Area
 *
 * 01. Accordion
 *
 * @global jQuery
 * @author Matteo Casonato
 */

(function ($) {
	'use strict';

	/**
	 * Accordion
	 *
	 * @requires plugins/jquery.accordion.js
	 */
	$('.js-access').accordion({
		panelSelector	: '.accordion',
		titleSelector	: '.accordion__header',
		contentSelector	: '.accordion__content',
		activeClass		: 'accordion--open'
	});

}(jQuery));