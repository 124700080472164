/**
 * Header
 *
 * 01. Scroll Hidden/Visible
 * 02. Night & Day
 * 03. Navigation
 *
 * @global jQuery
 * @author Matteo Casonato
 */

(function ($) {
    'use strict';

    /**
     * Scroll Hidden/Visible
     *
     * @summary Header scroll hidden and visible
     */
    var header = $('.js-header'),
        headerFixed = 'header--fixed',
        headerVisible = 'header--visible',
        headerHeight = header.height(),
        $window = $(window);

    $window.on(
        'scroll',
        {
            previousTop: 0
        },
        function () {
            var currentTop = $window.scrollTop();

            // check if user is scrolling up
            if (currentTop < this.previousTop) {
                // if scrolling up...
                if (currentTop > 0 && header.hasClass(headerFixed)) {
                    header.addClass(headerVisible);
                } else {
                    header.removeClass(headerVisible + ' ' + headerFixed);
                }
            } else if (currentTop != this.previousTop) {
                // if scrolling down...
                header.removeClass(headerVisible);
                if (currentTop > headerHeight && !header.hasClass(headerFixed)) {
                    header.addClass(headerFixed);
                }
            }
            this.previousTop = currentTop;
        }
    );


    /**
     * Night & Day
     *
     * @summary Switch color logo and trigger by sectionclass
     * @requires plugins/jquery.midnight.js
     */
    $('.js-midnight').midnight({
        headerClass: 'midnight',
        innerClass: 'midnight__inner',
        defaultClass: 'default'
    });

}(jQuery));


/**
 * Navigation
 *
 * @summary Full-Screen Pushing Navigation
 * @link http://tympanus.net/Development/FullscreenOverlayStyles/index7.html
 */
(function ($) {
    'use strict';
    
    var trigger = $('.js-trigger'),
        overlay = $('.js-nav'),
        html = $('html'),
        transEndEventNames = {
            'WebkitTransition': 'webkitTransitionEnd',
            'MozTransition': 'transitionend',
            'OTransition': 'oTransitionEnd',
            'msTransition': 'MSTransitionEnd',
            'transition': 'transitionend'
        },
        transEndEventName = transEndEventNames[ Modernizr.prefixed( 'transition' ) ],
        support = { transitions : Modernizr.csstransitions };

    function onEndTransition() {
        if (support.transitions) {
            overlay.off(transEndEventName, onEndTransition);
        }

        overlay.data('is-open', !overlay.data('is-open'));
    }

    function toggleOverlay() {
        if (overlay.data('is-open')) {
            html.removeClass('is-nav-open');
            overlay.removeClass('is-open');
        } else {
            html.addClass('is-nav-open');
            overlay.addClass('is-open');
        }

        if (support.transitions) {
            overlay.on(transEndEventName, onEndTransition);
        } else {
            onEndTransition();
        }
    }

    trigger.on('click', toggleOverlay);

}(jQuery));